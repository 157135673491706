import React from "react";
import { Route, Redirect } from "react-router-dom";
import isLogin from '../helper/isLogin';

const ConditionRoute = ({ component: Component, layout: Layout, auth, type, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (type == 'auth' && isLogin() == true) {
                    return <Redirect to="/dashboard" />
                } else if (type == 'private' && isLogin() != true) {
                    return <Redirect to="/login" />
                }
                return <Component {...props} />
            }}
        />
    )
};
export default ConditionRoute;