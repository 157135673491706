import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getUserListChart } from "../../Action/Swapping";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export default function TrafficByLocation(props) {
  const [usersCount, setusersCount] = useState([])

  const getchartdata = async () => {
    try {
      const { result } = await getUserListChart();
      const extractedUserCounts = daysOfWeek.map(day => {
        const entry = result.find(item => item._id === daysOfWeek.indexOf(day) + 1);
        return entry ? parseInt(entry?.count) : 0;
      });

      setusersCount(extractedUserCounts);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };
  
  

  useEffect(() => {
    getchartdata()
  }, [])

  const chartData = {
    series: usersCount,
    labels: daysOfWeek,
  };

  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: chartData.labels,
    stroke: {
      width: "0",
    },
    legend: {
      labels: {
        colors: "#fff",
        useSeriesColors: false,
      },
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  console.log(usersCount,'countryNamecountryNamecountryName')
  return (
    <>
      <div className="traffic_chart_flex">
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          type="donut"
          // width={450}
          height={400}
        />
      </div>
    </>
  );
}