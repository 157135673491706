import isEmpty from "is-empty";
import Web3 from "web3";

export async function validateUrl(url) {
  console.log("faceUrl: ", url);
  console.log("faceUrl: ", url.linkedin);

  var errors = {};

  var facebookUrl = url.facebook;
  var twitterUrl = url.twitter;
  var linkedinUrl = url.linkedin;
  var telegramUrl = url.telegram;
  var youtubeUrl = url.youtube;

  if (facebookUrl) {
    const regex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/;
    const validFacebookUrl = regex.test(String(facebookUrl.toLowerCase()));
    if (!validFacebookUrl) {
      errors.facebookUrl = "Please Enter Valid Facebook Url";
    }
  }
  if (twitterUrl) {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/?$/;
    const validTwitterUrl = regex.test(String(twitterUrl.toLowerCase()));
    if (!validTwitterUrl) {
      errors.twitterUrl = "Please Enter Valid twitter url";
    }
  }
  if (linkedinUrl) {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|company)\/([a-zA-Z0-9\-._]+)\/?$/;
    // const regex = /^https:\\/\\/[a-z]{2,3}\\.linkedin\\.com\\/.*$
    const validLinkedInUrl = regex.test(String(linkedinUrl.toLowerCase()));
    if (!validLinkedInUrl) {
      errors.linkedinUrl = "Please Enter Valid Linkedin url";
    }
  }
  if (telegramUrl) {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:t\.me|telegram\.me)\/([a-zA-Z0-9_]+)\/?$/;
    const validTelegramUrl = regex.test(String(telegramUrl.toLowerCase()));
    if (!validTelegramUrl) {
      errors.telegramUrl = "Please Enter Valid Telegram url";
    }
  }
  if (youtubeUrl) {
    const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
    const validYoutubeUrl = regex.test(String(youtubeUrl.toLowerCase()));
    if (!validYoutubeUrl) {
      errors.youtubeUrl = "Please Enter Valid Youtube url";
    }
  }

  return errors;
}
export async function validateImage(details) {
  console.log("detaaaaaaaaaaaaaails: ", details);
  var errors = {};

  let imageFormat = /\.(svg|png|PNG)$/;
  if (isEmpty(details.facebook)) {
    errors.facebookUrl = "Facebook field is required";
  }
  if (isEmpty(details.instagram)) {
    errors.instagramUrl = "Instagram field is required";
  }
  if (isEmpty(details.telegram)) {
    errors.telegramUrl = "Telegram field is required";
  }
  if (isEmpty(details.youtube)) {
    errors.youtubeUrl = "Youtube field is required";
  }
  if (isEmpty(details.twitter)) {
    errors.twitterUrl = "Twitter field is required";
  }
  if (isEmpty(details.reddit)) {
    errors.redditUrl = "Reddit field is required";
  }
  return errors;
}
export async function FarmValidate(details) {
  console.log(details, "detailsdetails");
  var web3 = new Web3(window.ethereum);
  console.log(details, "details1700");
  var errors = {};

  var imageFormat = /\.(svg|png|PNG)$/;
  var onlynumerwithdecimal = /^-?(?!0+$)\d+(\.\d+)?$/;
  var onlynumbers = /^-?[1-9]\d*$/;
  var fromtokens = web3.utils.isAddress(details.tokenAddresses);
  var totokens = web3.utils.isAddress(details.quoteTokenAdresses);
  if (details.logoURI == "") {
    if (details.lplogo && details.lplogo.size) {
      if (details.lplogo.size > 2048000) {
        errors.logoURI = "Image size must be less than 2mb";
      } else if (!imageFormat.test(details.lplogo.name)) {
        errors.logoURI = "Invalid Image Format.only svg,png accepted";
      }
    } else {
      errors.logoURI = "Please Select Logo";
    }
  }
  if (isEmpty(details.tokenSymbol)) {
    errors.tokenSymbol = "This field is required";
  }
  if (isEmpty(details.tokenAddresses)) {
    errors.fromaddress = "This field is required";
  } else if (!fromtokens) {
    errors.fromaddress = "Please enter valid contract address";
  }
  if (isEmpty(details.quoteTokenAdresses)) {
    errors.quoteTokenAdresses = "This field is required";
  } else if (!totokens) {
    errors.quoteTokenAdresses = "Please enter valid contract address";
  }
  if (isEmpty(details.quoteTokenSymbol)) {
    errors.quoteTokenSymbol = "This field is required";
  }
  if (isEmpty(details.depositFee)) {
    errors.depositFee = "This field is required";
  } else if (details.depositFee <= 0) {
    errors.depositFee = "DepositFee must contain only positive numbers";
  } else if (!onlynumerwithdecimal.test(details.depositFee)) {
    errors.depositFee = "DepositFee must contain only numbers";
  }

  return errors;
}
export async function PoolValidate(details) {
  console.log(details, "detailsdetails");

  var web3 = new Web3(window.ethereum);
  console.log(details, "details1700");
  var errors = {};

  var imageFormat = /\.(svg|png|PNG)$/;
  var onlynumerwithdecimal = /^-?(?!0+$)\d+(\.\d+)?$/;
  var onlynumbers = /^-?[1-9]\d*$/;
  var fromtokens = web3.utils.isAddress(details.tokenAddresses);

  if (details.logoURI == "") {
    if (details.lplogo && details.lplogo.size) {
      if (details.lplogo.size > 2048000) {
        errors.logoURI = "Image size must be less than 2mb";
      } else if (!imageFormat.test(details.lplogo.name)) {
        errors.logoURI = "Invalid Image Format.only svg,png accepted";
      }
    } else {
      errors.logoURI = "Please Select Logo";
    }
  }
  if (isEmpty(details.tokenSymbol)) {
    errors.tokenSymbol = "This field is required";
  }
  if (isEmpty(details.tokenAddresses)) {
    errors.fromaddress = "This field is required";
  } else if (!fromtokens) {
    errors.fromaddress = "Please enter valid contract address";
  }

  if (isEmpty(details.depositFee)) {
    errors.depositFee = "This field is required";
  } else if (details.depositFee <= 0) {
    errors.depositFee = "DepositFee must contain only positive numbers";
  } else if (!onlynumerwithdecimal.test(details.depositFee)) {
    errors.depositFee = "DepositFee must contain only numbers";
  }

  return errors;
}
