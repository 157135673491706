import axios from "../config/axios";
import config from "../config/config";

const baseUrl = config.baseUrl;
export const getswapList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-swap-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&chainId=` +
        data.network +
        `&pair=` +
        data.pair +
        `&startDate=` +
        data.startDate +
        `&EndDate=` +
        data.EndDate,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
      pairres: respData.data.paires,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const gettokenList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-token-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status +
        `&chainId=` +
        data.network +
        `&startdate=` +
        data.startdate,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const tokenStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-token-status`,
      data: { _id: data },
    });
    return {
      message: respData.data.message,
    };
  } catch (error) {
    return {
      errors: returnErr(error),
    };
  }
};
export const tokenPopular = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-token-popular`,
      data: { _id: data },
    });
    return {
      message: respData.data.message,
    };
  } catch (error) {
    return {
      errors: returnErr(error),
    };
  }
};
export const addToken = async (param) => {
  var adminrole = localStorage.getItem("adminrole");
  let newname = `${param.name.toLowerCase()}.png`;
  var formData = new FormData();
  formData.append("name", param.name);
  formData.append("symbol", param.symbol);
  formData.append("decimals", param.decimals);
  formData.append("totalSupply", param.totalSupply);
  formData.append("address", param.address);
  formData.append("chainId", param.chainId);
  formData.append("file", param.logoURI, newname);
  formData.append("status", param.status);
  formData.append("tokendate", param.tokendate);
  formData.append("role", adminrole);
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-add-token`,
      data: formData,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
      exits: respData.data && respData.data.exits ? respData.data.exits : "",
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const updateToken = async (param) => {
  var adminrole = localStorage.getItem("adminrole");
  console.log(param, "param");
  let newname = `${param.name.toLowerCase()}.png`;
  var formData = new FormData();
  formData.append("_id", param._id);
  formData.append("name", param.name);
  formData.append("symbol", param.symbol);
  formData.append("decimals", param.decimals);
  formData.append("totalSupply", param.totalSupply);
  formData.append("address", param.address);
  formData.append("chainId", param.chainId);
  if (param.logoURI != "") {
    formData.append("file", param.logoURI, newname);
  }
  formData.append("status", param.status);
  formData.append("tokendate", param.tokendate);
  formData.append("role", adminrole);
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-update-token`,
      data: formData,
    });
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
      exits: respData.data && respData.data.exits ? respData.data.exits : "",
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const deleteToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-delete-token`,
      data: data,
    });
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const StarToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-star-token`,
      data: data,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getfeeList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-fee-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status +
        `&chainId=` +
        data.network +
        `&type=` +
        data.type,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getUserListChart = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${baseUrl}/admin-users-chart`,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getUserRecentTransaction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url:
        `${baseUrl}/transactions-history?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search,
      // 'data': data,
    });
    console.log("respDataaaaaaaaaaaaaaa: ", respData);

    return {
      loading: false,
      result:
        respData && respData.data && respData.data.data
          ? respData.data.data
          : [],
      totalrecords:
        respData && respData.data && respData.data.totalrecords
          ? respData.data.totalrecords
          : 0,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};
export const getHistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-swap-history?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    console.log("errsss: ", err);
    return {
      errors: returnErr(err),
    };
  }
};
export const getFPcommission = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-fp-commission?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getLiqutityHistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-liqutity-history?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const getUserList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-users-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search,
    });
    console.log("respDataaaaaaaaaaaaaaa: ", respData);

    return {
      loading: false,
      result:
        respData && respData.data && respData.data.result
          ? respData.data.result
          : [],
      totalrecords:
        respData && respData.data && respData.data.totalrecords
          ? respData.data.totalrecords
          : 0,
    };
  } catch (err) {
    return {
      loading: false,
      error: returnErr(err),
    };
  }
};

function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}
