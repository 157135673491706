import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import {
    Checkbox,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CommissionHistyAction } from "../Action/ApiAction.js";
import { getNetworkName, getTxLink } from "../helper/networkdatas.js";
import { convert } from "../helper/convert.js";
import moment from "moment";
import { formatNumber } from "../helper/custommath.js";
import config from "../config/config.js"
import { getLiqutityHistory, getUserRecentTransaction } from "../Action/Swapping.jsx";
import { capitalizeFirstLetter } from "../helper/capitalize.js";
// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
export default function LiquidityHistory() {
    // For Datatable Starts
    createTheme(
        "dark",
        {
            text: {
                primary: "#FFFFFF",
                secondary: "rgba(255, 255, 255, 0.7)",
                disabled: "rgba(0,0,0,.12)",
            },
            background: {
                default: "#111111",
            },
            context: {
                background: "#111111",
                text: "#DA1818",
            },
            divider: {
                default: "rgb(17 17 17)",
            },
            button: {
                default: "#FFFFFF",
                focus: "rgba(255, 255, 255, .54)",
                hover: "rgba(255, 255, 255, .12)",
                disabled: "rgba(255, 255, 255, .18)",
            },
            selected: {
                default: "rgba(0, 0, 0, .7)",
                text: "#FFFFFF",
            },
            highlightOnHover: {
                default: "rgba(0, 0, 0, .7)",
                text: "#FFFFFF",
            },
            striped: {
                default: "rgba(0, 0, 0, .87)",
                text: "#FFFFFF",
            },
        },
        "dark"
    );
    // For Datatable Ends
    const [record, setRecord] = useState({
        'data': [],
        'count': 0
    })
    const [filter, setFilter] = useState({
        skip: 1,
        limit: 10,
        search: '',
    })
    const columns = [
        {
            name: "Useraddress",
            selector: (row) => row.useraddress,
        },
        {
            name: "Type",
            selector: (row) => capitalizeFirstLetter(row.type),
        },
        {
            name: "Date",
            selector: (row) => row.date,
        },
        {
            name: "From",
            selector: (row) => convert(row.fromAmt) + ' ' + row.fromSym,
        },
        {
            name: "To",
            selector: (row) => convert(row.toAmt) + ' ' + row.toSym,
        },
        {
            name: "Hash",
            selector: (recentswaplist) => recentswaplist.txId,
            cell: (recentswaplist) =>
                <a href={getTxLink(recentswaplist.chainId) + 'tx/' + recentswaplist.txId} target="_blank">
                    <i className="fa-solid fa-arrow-up-long fa-rotate-45"></i>
                </a>
        }
    ];

    useEffect(() => {
        getSwapList(filter)
    }, [])

    async function getSwapList(reqQuery) {
        var { result, totalrecords } = await getLiqutityHistory(reqQuery)
        let resultArr = []
        result && result.length > 0 && result.map((item, key) => {
            resultArr.push({
                '_id': item?._id,
                'type': item?.actiontype,
                "txId": item?.txid,
                "fromAmt": item?.fromAmt,
                "toAmt": item?.toAmt,
                "fromSym": item?.fromSym,
                "toSym": item?.toSym,
                "chainId": config.netWorkversion,
                'date': moment(item?.createdAt).format('MMMM DD,YYYY hh:mm'),
                "useraddress": item?.useraddress
            })
        })
        setRecord({
            'data': resultArr,
            count: totalrecords
        })
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
        setFilter(filterData)
        getSwapList(filterData)
    };
    const handlePageChange = page => {
        let filterData = { ...filter, ...{ 'page': page, 'skip': page } }
        setFilter(filterData)
        getSwapList(filterData)
    };
    const handleSearch = async (event) => {
        let filterData = { ...filter, ...{ 'search': event.target.value } }
        setFilter(filterData)
        getSwapList(filterData)
    }

    return (
        <>
            <ScrollToTopOnMount />
            <div className="apppage_wrapper">
                <div className="app_page">
                    <Navbar />
                    <div className="page_wrapper">
                        <Sidebar />
                        <div className="page_content">
                            <div className="container-fluid">
                                <h2 className="page_title">Liquidity history</h2>
                                <div className="row py-3">
                                    <div className="col-lg-12">
                                        <div className="dashbox_inner">
                                            <FormControl
                                                sx={{ width: "70ch" }}
                                                variant="outlined"
                                                size="small"
                                                className="search_input"
                                            >
                                                <InputLabel htmlFor="outlined-adornment-search">
                                                    Search by useraddress, type
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-search"
                                                    type="text"
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <IconButton aria-label="Search Icon" edge="end">
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    onChange={handleSearch}
                                                    label="Search by useraddress, type"
                                                />
                                            </FormControl>
                                            <DataTable
                                                columns={columns}
                                                data={record.data}
                                                paginationTotalRows={record.count}
                                                responsive
                                                theme="dark"
                                                pagination
                                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                paginationServer
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                            // selectableRows
                                            // selectableRowsComponent={Checkbox}
                                            // selectableRowsComponentProps={selectProps}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}