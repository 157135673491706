import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Checkbox,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Menu,
  ListItemIcon,
  Divider,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { gettokenList, tokenStatus, deleteToken } from "../Action/Swapping";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert";
import { getNetworkName } from "../helper/networkdatas";
import moment from "moment";
import AddToken from "../components/dashboard/AddToken.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getTokenLogo } from "../helper/networkdatas";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
export default function TokenManagement() {
  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#111111",
      },
      context: {
        background: "#111111",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(17 17 17)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );
  // For Datatable Ends
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetch, setfetch] = React.useState(false);
  const [status, setStatus] = React.useState("all");
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });
  const [filter, setFilter] = useState({
    skip: 1,
    limit: 10,
    search: "",
    network: "all",
    status: "all",
    startdate: "",
  });
  const [network, setNetwork] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [Edit, setEdit] = React.useState(false);
  const [Editdata, setEditdata] = React.useState({});

  const columns = [
    {
      name: "Token",
      selector: (row) => row.token,
    },
    {
      name: "Status",
      selector: (row) => (row.status == "Live" ? "Active" : "Inactive"),
    },
    {
      name: "Blockchain",
      selector: (row) => row.blockchain,
    },
    {
      name: "Token Time",
      selector: (row) => row.date,
    },
    {
      name: "Activity",
      selector: (row, item) => {
        return row.activity;
      },
    },
    {
      selector: (row) => {
        return (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, row)}
              size={"small"}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu"
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => showPopup(row._id)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <Typography variant="inherit">Edit</Typography>
              </MenuItem>

              <Divider />

              <MenuItem onClick={() => onDelete(Editdata._id)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <Typography variant="inherit">Delete</Typography>
              </MenuItem>
            </Menu>
          </div>
        );
      },
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  const handleClick = (event, data) => {
    console.log("dataaa222aaaaaaa: ", data);
    setEdit(true);
    var value = {
      _id: data._id,
      symbol: data.symbol,
      address: data.address,
      chainId: data.chainId,
      logoURI: data.logoURI,
      status: data.status == "Live" ? true : false,
      symbol: data.symbol,
      tokendate: data.tokendate,
    };
    setEditdata(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditdata((prevData) => ({ ...prevData, address: "", logoURI: "" }));
  };

  async function onDelete(data) {
    if (window.confirm("Are you sure you want to Delete?")) {
      let input = { _id: data };
      let { success, message } = await deleteToken(input);
      if (success == false) {
        setAnchorEl(null);
        toastAlert("error", message);
      } else {
        toastAlert("success", message);
        setAnchorEl(null);
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      }
    } else {
      setAnchorEl(null);
    }
  }

  function showPopup(data) {
    setAnchorEl(null);
    window.$("#add_token").modal("show");
  }
  const handleChange = (event) => {
    let filterData = { ...filter, ...{ status: event.target.value } };
    setFilter(filterData);
    getSwapList(filterData);
    setStatus(event.target.value);
  };
  const handleDateChange = (date) => {
    let filterData = { ...filter, ...{ startdate: date } };
    setFilter(filterData);
    getSwapList(filterData);
    setStartDate(date);
  };
  useEffect(() => {
    getSwapList(filter);
  }, [fetch]);

  async function getSwapList(reqQuery) {
    var response = await gettokenList(reqQuery);
    console.log("responssdsdsdsdsde: ", response);
    let resultArr = [];
    let pairArr = [];
    response.result.map((item, key) => {
      var inputDate = new Date(item.createdAt);
      var currentDate = new Date();
      var timeDifference = currentDate - inputDate;
      var daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      resultArr.push({
        token: (
          <div className="admin_profile">
            <img
              src={getTokenLogo(item.logoURI, item.chainId)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = config.defaultLogo;
              }}
              className="img-fluid rounded-circle"
              alt="logo"
            />
            <span>{item.symbol}</span>
          </div>
        ),
        status: (
          <div className="custom_toggle_switch">
            <input
              type="checkbox"
              id={"check" + key}
              checked={item.status === "Live" ? true : false}
              onChange={(e) => handleToggle(e, item._id)}
            />
            <label for={"check" + key}>
              <span className="handler">
                <span className="handler-inner"></span>
              </span>
              <span className="toggle_text">Yes</span>
              <span>No</span>
              <span className="toggle_bg"></span>
            </label>
          </div>
        ),
        blockchain: getNetworkName(item.chainId),
        _id: item._id,
        date: moment(item.createdAt).format("MMMM DD,YYYY hh:mm"),
        activity: daysAgo == 0 ? "Today" : daysAgo + " days ago",
        address: item.address,
        logoURI: getTokenLogo(item.logoURI, item.chainId),
        status: item.status,
        name: item.name,
        chainId: item.chainId,
        symbol: item.symbol,
        tokendate: item.tokendate,
        // "role": item.role
      });
    });
    setRecord({
      data: resultArr,
      count: response.totalrecords,
    });
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
    getSwapList(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page, skip: page } };
    setFilter(filterData);
    getSwapList(filterData);
  };
  const handleToggle = async (e, itemId) => {
    const data = await tokenStatus(itemId);
    toastAlert("success", data.message, "success");
    getSwapList(filter);
  };
  const networkHandler = async (event) => {
    let filterData = { ...filter, ...{ network: event.target.value } };
    setFilter(filterData);
    getSwapList(filterData);
    setNetwork(event.target.value);
  };
  const handleSearch = async (event) => {
    let filterData = { ...filter, ...{ search: event.target.value } };
    setFilter(filterData);
    getSwapList(filterData);
  };
  function openPopup() {
    setEdit(false);
    window.$("#add_token").modal("show");
  }
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <h2 className="page_title">Token Management</h2>
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox_inner">
                      <div className="top_filter_wrapper">
                        <FormControl
                          sx={{ width: "70ch" }}
                          variant="outlined"
                          size="small"
                          className="search_input"
                        >
                          <InputLabel htmlFor="outlined-adornment-search">
                            Search by token
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-search"
                            type="text"
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton aria-label="Search Icon" edge="end">
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={handleSearch}
                            label="Search by token"
                          />
                        </FormControl>
                        <Button
                          variant="contained"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={openPopup}
                        >
                          Add Token
                        </Button>
                        <FormControl
                          sx={{ minWidth: 150 }}
                          size="small"
                          variant="filled"
                          className="fiiled_filter"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={status}
                            label="Status"
                            onChange={handleChange}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"Live"}>Active</MenuItem>
                            <MenuItem value={"Finished"}>Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <DataTable
                        columns={columns}
                        data={record.data}
                        paginationTotalRows={record.count}
                        responsive
                        theme="dark"
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Token */}
      <AddToken Edit={Edit} Editdata={Editdata} setfetch={setfetch} />
    </>
  );
}
