import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import {
  Button
} from "@mui/material";
import { validation } from "./validation";
import { login } from "../Action/ApiAction";
import isEmpty from "is-empty";
import { toastAlert } from "../helper/toastAlert";
import { tokencheck } from "../helper/jwttoken"
import { setAuthToken } from '../helper/localstorage';
import {getUrlList} from "../Action/UserAction"
import config from "../config/config"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  email: '',
  password: '',
  
}
export default function Login() {
  const history = useHistory()
  const [formValue, setFormValue] = useState(initialFormValue);
  const { email, password,twoFACode } = formValue;
  const [load, setload] = useState(false)
  const [validateError, setValidateError] = useState({});
  
  const [logodata, setLogodata] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError({})
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setload(true)
    let value = {
      email: email,
      password: password,
    }
    let error = validation(value)
    if (!isEmpty(error)) {
      setload(false)
      setValidateError(error)
    }
    else {
      let { message, errors, token, success,status } = await login(value);
      console.log(' messagssssssssssssssssssssss: ',  message, errors, token, success,status);
      if (!isEmpty(errors)) {
        setload(false)
        setValidateError(errors)
      }
       if (success == true) {
      
          setAuthToken(token)
          var check = await tokencheck(token)
          if (check && check.role != '') {
            localStorage.setItem("adminrole", check.role)
            localStorage.setItem("acesdsjofihw", check.access)
          }
          setload(false)
          toastAlert('success', message, 'login')
          history.push('/dashboard')
          // window.location.href='/dashboard'
        }
      if  (success == false) {
        setload(false)
        toastAlert('error', message)
      }
    }
  }

  return (
    <>
      <ScrollToTopOnMount />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form">
                <h2>Welcome</h2>
                <p>PLEASE LOGIN TO ADMIN DASHBOARD</p>
                <div className="form-group">
                  <label>Email Address</label>
                  <input className="form-control" type="email" name="email"
                    onChange={handleChange} value={email} />
                  {
                    validateError.email && <p className="error-message" style={{ color: "red" }}>{validateError.email}</p>
                  }
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input className="form-control" type="password" name="password" onChange={handleChange} value={password} />
                  {
                    validateError.password && <p className="error-message" style={{ color: "red" }}>{validateError.password}</p>
                  }
                </div>
               
                {/* <Link to="/forgot-password">Forgot Password?</Link> */}
                <div className="text-center mt-3">
                  <Button variant="contained" onClick={handleFormSubmit}>Sign In {load && <i class="fas fa-spinner fa-spin"></i>}</Button>
                </div>
              </form>
            </div>
            <div className="auth_right">


<img src={logo} alt="Logo" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}