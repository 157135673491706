import jwt_decode from "jwt-decode";
import config from "../config/config"
// import { createSocketUser } from "./socketConnectivity";

export const tokencheck = async(token)=>{
    if(token){
     var token = token.replace('Bearer ', '')
     const decoded = await jwt_decode(token);
     return decoded
    //  createSocketUser(decoded._id)
     return decoded._id
    }
    else{
     return null
    }
   }