import axios from "axios";
import config from "../config/config";

const baseUrl = config.baseUrl;
export const addFarm = async (param) => {
  var formData = new FormData();
  formData.append("pid", param.pid);
  formData.append("lpSymbol", param.lpSymbol);
  formData.append("isTokenOnly", param.isTokenOnly);
  formData.append("lpAddresses", param.lpAddresses);
  formData.append("tokenSymbol", param.tokenSymbol);
  formData.append("tokenAddresses", param.tokenAddresses);
  formData.append("quoteTokenSymbol", param.quoteTokenSymbol);
  formData.append("quoteTokenAdresses", param.quoteTokenAdresses);
  formData.append("depositFee", param.depositFee);
  formData.append("file", param.logoURI);
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-add-forms`,
      data: formData,
    });
    return {
      result: respData.data.result,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getfarmList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-farms-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const checkLPaddress = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/check-lpaddress`,
      data: data,
    });
    console.log(respData, "aaaaaaaaaa");
    return {
      result: respData.data.result,
      status: respData.data.status,
      message: respData.data.message,
      errors: respData.data.errors,
    };
  } catch (err) {
    console.log("errsss111: ", err);
    return {
      errors: returnErr(err),
    };
  }
};

export const updateForm = async (param) => {
  console.log(param, "param");
  var formData = new FormData();
  formData.append("_id", param._id);
  formData.append("pid", param.pid);
  formData.append("lpSymbol", param.lpSymbol);
  formData.append("isTokenOnly", param.isTokenOnly);
  formData.append("lpAddresses", param.lpAddresses);
  formData.append("tokenSymbol", param.tokenSymbol);
  formData.append("tokenAddresses", param.tokenAddresses);
  formData.append("quoteTokenSymbol", param.quoteTokenSymbol);
  formData.append("quoteTokenAdresses", param.quoteTokenAdresses);
  formData.append("depositFee", param.depositFee);
  formData.append("file", param.logoURI);

  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-update-forms`,
      data: formData,
    });
    return {
      success: respData.data.success,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    console.log(err, "eeeeeeeeeeee");
    return {
      errors: returnErr(err),
    };
  }
};
export const deleteFarm = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/admin-delete-forms`,
      data: data,
    });
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getpoolList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/admin-pools-list?skip=` +
        data.skip +
        `&limit=` +
        data.limit,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}
