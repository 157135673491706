import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import {
  Button  
} from "@mui/material";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function NotFound(props) {
  const history = useHistory()
  const handleClick = () => {
    history.push('/dashboard')
  }

  return (
    <>
      <ScrollToTopOnMount />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main auth_main_forgot" style={{"padding":"50px","background":"none"}}>
            <div className="auth_left">
              <form className="auth_form">
                <img src={logo} alt="Logo" className="img-fluid" />
                <h2>Page Not Found</h2>
               
                <div className="text-center mt-5">
                  <Button variant="contained" onClick={handleClick}>Back To DashBoard</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
