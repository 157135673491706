import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Checkbox,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Menu,
  ListItemIcon,
  Divider,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { deleteFarm } from "../Action/FarmAction";
import { getfarmList } from "../Action/FarmAction";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert";
import moment from "moment";
import AddFarm from "../components/dashboard/AddFarm.js";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };
export default function Farms() {
  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#111111",
      },
      context: {
        background: "#111111",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(17 17 17)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );
  // For Datatable Ends
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetch, setfetch] = React.useState(false);
  const [status, setStatus] = React.useState("all");
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });
  const [filter, setFilter] = useState({
    skip: 1,
    limit: 10,
    // search: '',
    network: "all",
    status: "all",
    startdate: "",
  });
  const [network, setNetwork] = useState("all");
  const [startDate, setStartDate] = useState(new Date());
  const [Edit, setEdit] = React.useState(false);
  const [Editdata, setEditdata] = React.useState();
  const [search, setSearch] = useState("");

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Token",
      selector: (row) => row.token,
    },
    {
      name: "LP Symbol",
      selector: (row) => row.lpSymbol,
    },
    {
      name: "DepositFee",
      selector: (row) => row.depositFee,
    },

    {
      name: "Token Address",
      selector: (row) => (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => copyToClipboard(row.tokenAddresses)}
        >
          {halfAddrShow(row.tokenAddresses)}
        </a>
      ),
    },
    {
      selector: (row) => {
        console.log(row._id, "ghjry");
        return (
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleClick(event, row)}
              size={"small"}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu"
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => showPopup(row)}>
                <ListItemIcon>
                  <EditIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <Typography variant="inherit">Edit</Typography>
              </MenuItem>

              <Divider />

              <MenuItem onClick={() => onDelete(row._id)}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <Typography variant="inherit">Delete</Typography>
              </MenuItem>
            </Menu>
          </div>
        );
      },
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toastAlert("success", "Copied!");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const handleClick = (event, data) => {
    console.log(event, data, "afswgfsfhgdtfv");
    setEdit(true);
    var value = {
      _id: data._id,
      user: data.user,
      name: data.name,
      tokenSymbol: data.tokenSymbol,
      tokenAddresses: data.tokenAddresses,
      quoteTokenSymbol: data.quoteTokenSymbol,
      quoteTokenAdresses: data.quoteTokenAdresses,
      depositFee: data.depositFee,
      lplogo: data.lplogo,
      logoURI: data.logoURI,
      pid: data.pid,
      lpAddresses: data.lpAddresses,
      tokenSymbol: data.tokenSymbol,
      lpSymbol: data.lpSymbol,
    };
    setEditdata(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEditdata((prevData) => ({
      ...prevData,
      tokenAddresses: "",
      quoteTokenAdresses:"",
      logoURI: "",
      depositFee: "",
      tokenSymbol: "",
      quoteTokenSymbol: "",
    }));
    setAnchorEl(null);
  };
  async function onDelete(data) {
    console.log(data, "datajdfg");
    if (window.confirm("Are you sure you want to Delete?")) {
      let input = { _id: data };
      let { success, message } = await deleteFarm(input);
      if (success == false) {
        setAnchorEl(null);
        toastAlert("error", message);
      } else {
        toastAlert("success", message);
        setAnchorEl(null);
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      }
    } else {
      setAnchorEl(null);
    }
  }

  const showPopup = (data) => {
    setAnchorEl(null);
    window.$("#add_farm").modal("show");
  };
  const handleChange = (event) => {
    let filterData = { ...filter, ...{ status: event.target.value } };
    setFilter(filterData);
    getSwapList(filterData);
    setStatus(event.target.value);
  };
  const handleDateChange = (date) => {
    let filterData = { ...filter, ...{ startdate: date } };
    setFilter(filterData);
    getSwapList(filterData);
    setStartDate(date);
  };
  useEffect(() => {
    getSwapList(filter);
  }, [fetch, search]);

  async function getSwapList(reqQuery) {
    var response = await getfarmList(reqQuery);
    let resultArr = [];

    response?.result?.map((item, key) => {
      console.log(item, "itemitemitemitem");
      console.log(config.imageUrl + "farms/" + item.logoURI, "xhtfduerhg");
      resultArr.push({
        token: (
          <div className="admin_profile">
            <img
              src={config.imageUrl + "farms/" + item.logoURI}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = config.defaultLogo;
              }}
              className="img-fluid rounded-circle"
              alt="logo"
            />
            <span>{item.symbol}</span>
          </div>
        ),
        lpSymbol: item.lpSymbol,
        depositFee: item.depositFee,
        _id: item._id,
        date: moment(item.createdAt).format("MM-DD-YY"),
        user: item.address,
        address: item.address,
        logoURI: item.logoURI,
        symbol: item.symbol,
        tokenSymbol: item.tokenSymbol,
        tokenAddresses: item.tokenAddresses,
        quoteTokenSymbol: item.quoteTokenSymbol,
        quoteTokenAdresses: item.quoteTokenAdresses,
        pid: item.pid,
        lpAddresses: item.lpAddresses,
        tokenSymbol: item.tokenSymbol,
      });
    });

    const filteredItems = resultArr?.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );

    setRecord({
      data: filteredItems,
      count: response.totalrecords,
    });
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
    getSwapList(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page, skip: page } };
    setFilter(filterData);
    getSwapList(filterData);
  };

  function openPopup() {
    setEditdata();
    setEdit(false);
    window.$("#add_farm").modal("show");
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <h2 className="page_title">Farms Management</h2>
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox_inner">
                      <div className="top_filter_wrapper">
                        <Button
                          variant="contained"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={openPopup}
                        >
                          Add Farm
                        </Button>
                      </div>
                      <FormControl
                        sx={{ width: "70ch" }}
                        variant="outlined"
                        size="small"
                        className="search_input"
                      >
                        <InputLabel htmlFor="outlined-adornment-search">
                          Search by token address
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-search"
                          type="text"
                          endAdornment={
                            <InputAdornment position="start">
                              <IconButton aria-label="Search Icon" edge="end">
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          onChange={(e) => setSearch(e.target.value)}
                          label="Search by useraddress, type"
                        />
                      </FormControl>
                      <DataTable
                        columns={columns}
                        data={record.data}
                        paginationTotalRows={record.count}
                        responsive
                        theme="dark"
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        paginationServer
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        selectableRows
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Farm */}
      <AddFarm Edit={Edit} Editdata={Editdata} setfetch={setfetch} />
    </>
  );
}
