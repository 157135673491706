import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/dashboard.js";

import TokenManagement from "./pages/token-management.js";
import ChangePassword from "./pages/change-password.js";
import Login from "./pages/login.js";
import ForgotPassword from "./pages/forgot-password.js";
import { SnackbarProvider } from "notistack";
import ConditionRoute from "./routes/ConditionRoute.js";
import ResetPassword from "./pages/reset-password.js";
import SiteSettings from "./pages/site-settings";
import TransactionHistory from "./pages/transaction-history.js";
import Userslist from "./pages/users-list.js";
import NotFound from "./pages/not-found.js";

import Farms from "./pages/farms";
import Pools from "./pages/pool";
import Test from "./pages/Test.js";
import HomeCms from "./pages/homecms.js";
import SwapHistory from "./pages/swap-history.js";
import LiquidityHistory from "./pages/liquidity-history.js";
import YieldCommission from "./pages/yield-commission.js";
const AppWrapper = () => {
  return (
    <BrowserRouter basename="/">
      <SnackbarProvider />

      <Switch>
        <ConditionRoute exact path="/test" component={Test} type={"private"} />

        <ConditionRoute exact path="/login" component={Login} type={"auth"} />
        <ConditionRoute
          exact
          path="/change-password"
          component={ChangePassword}
          type={"private"}
        />
        {/* <ConditionRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
            type={"auth"}
          /> */}
        <ConditionRoute
          exact
          path="/transactions"
          component={TransactionHistory}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/swap-history"
          component={SwapHistory}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/liquidity-history"
          component={LiquidityHistory}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/yield-commission"
          component={YieldCommission}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/users-list"
          component={Userslist}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/reset-password/:userId"
          component={ResetPassword}
          type={"auth"}
        />
        <ConditionRoute exact path="/" component={Dashboard} type={"private"} />
        <ConditionRoute
          exact
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />

        <ConditionRoute
          exact
          path="/farms"
          component={Farms}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/pools"
          component={Pools}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/token-management"
          component={TokenManagement}
          type={"private"}
        />

        <ConditionRoute
          exact
          path="/site-settings"
          component={SiteSettings}
          type={"private"}
        />
        <ConditionRoute
          exact
          path="/cms-add"
          component={HomeCms}
          type={"private"}
        />

        <ConditionRoute exact path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
export default AppWrapper;
