import axios from '../config/axios'
import config from "../config/config";
import { setAuthToken } from '../helper/localstorage';
const baseUrl = config.baseUrl;

//adminLogin

export const login = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/login`,
            'data': data
        });
        return {
            success: respData.data.success,
            errors: respData.data.errors,
            token: respData.data.token,
            message: respData.data.message,
            status: respData && respData.data && respData.data.status ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log('err: ', err);
        return {
            errors: err?.data?.errors,
        }
    }
}

export const getdetails = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/admin-total-details`,
        })
        console.log('sdfsdfsdfrespData: ', respData);

        return {
            totalusers: respData.data.totalusers,
            totalswapping: respData.data.totalswapping,
            totalliqutity: respData.data.totalliqutity,
            liqutitychart: respData.data.liqutitychart,
            swapchart: respData.data.swapchart,
            removecount:respData.data.removecount,
            transactioncount:respData.data.transactioncount,
            unstakeCounts:respData.data.unstakeCounts,
            stakeCounts:respData.data.stakeCounts,
            tokenscount:respData.data.tokenscount
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const getAdminDetailsAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/getAdminDetails`,
            data
        });
        return {
            status: 'true',
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/change-password`,
            data: data
        });
        return {
            success: respData.data.success,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}
export const ResetPasswordAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/reset-passwordd`,
            data: data
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}
export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/forgott-password`,
            data: data
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            errors: respData.data.errors,
            token: respData.data.token,
        }
    }
    catch (err) {
        return {
            loading: true,
            errors: returnErr(err),
            result: null
        }
    }
}

export const getAllAdminAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-adminInfo`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result && respData.data.result.adminData) ? respData.data.result.adminData : [],
            unchatresult: (respData && respData.data && respData.data.result && respData.data.result.unchatadmins) ? respData.data.result.unchatadmins : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const customerListAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/customerCount`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getuserListAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/get-userlist`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getToppairTradingAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getpair-tradinglist`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const saveMessages = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-chat`,
            data: data
        });
        return {
            loading: true,
            results: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}



export const recentSwapTransactionAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getswap-list`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const recentBridgeTransactionAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getbridge-list`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const AdminFeeChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/adminfee-chart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getTotalcountsAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/totalCalculations`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result && respData.data.result.data) ? respData.data.result.data : [],
            resulttwo: (respData && respData.data && respData.data.result && respData.data.result.datatwo) ? respData.data.result.datatwo : [],
            totalSales: (respData && respData.data && respData.data.result && respData.data.result.totalSalesUsdtSum) ? respData.data.result.totalSalesUsdtSum : [],
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const ActvityOverviewAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/activity_overview`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const totalvisitsChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/total_visitsChart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const trafficChartAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/traffic-Chart`,
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getsalesdataAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/sales-Chart`,
            data: data
        });
        return {
            loading: true,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const SaveBridgeFee = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-bridgefee`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed'
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const SaveSwapFee = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-swapfee`,
            data: data,
            headers: {
                Secretkey: config.secretKey
            }
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed'
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const getswapfees = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-swapfee`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {}
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const getFeeCommissionAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-feecommission`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
            result: (respData && respData.data && respData.data.result) ? respData.data.result : []
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const updateFeeCommission = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/update-feecommission`,
            data: data
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            message: (respData && respData.data && respData.data.message) ? respData.data.message : 'Failed',
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const WithdrawListAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/withdraw-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,

        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const CommissionHistyAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/commission-histy?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,
        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}
export const WithdrawHistoryAction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            url:
                `${baseUrl}/get-withdrawhisty?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        });
        return {
            loading: true,
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
            result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
            totalrecords: respData.data.totalCount,

        }
    }
    catch (err) {
        return {
            loading: true,
            error: returnErr(err),
            result: null
        }
    }
}

export const gettemplate = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-site-template?identifier=${data}`,
        })
        console.log(respData.data.data, 'apidata')
        return {
            result: respData.data.result,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const updateTemplate = async (data) => {
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/update-site-template`,
            data: data,
        })
        return {
            status:respData.data.status,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}