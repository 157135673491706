export const setAuthToken = (token) => {
    console.log('tosssssssssssssssssssken: ', token);
    localStorage.setItem('admin_token', token);
    return true
}
export const getAuthToken = () => {
    if (localStorage.getItem('admin_token')) {
        return localStorage.getItem('admin_token')
    }
    return '';
}
export const getAccessToken = () => {
    if (localStorage.getItem('acesdsjofihw')) {
        return localStorage.getItem('acesdsjofihw')
    }
    return '';
}
