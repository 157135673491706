import axios from '../config/axios'
import config from '../config/config'


const baseUrl = config.baseUrl
export const getwalletlist = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url:
                `${baseUrl}/admin-wallet-list?skip=` +
                data.skip +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search
        
        })
        return {
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const getUrlList = async (data) => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-social-url`,
        })
        return {
            result: respData.data.data,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

export const siteurl = async (data) => {
    console.log('dasssssssssssssssta: ', data);
    try {
        let respData = await axios({
            method: 'post',
            url: `${baseUrl}/site-social-url`,
            data: data,
        })
        return {
            status:respData.data.status,
            message: respData.data.message,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}

function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        return err.response.data.errors;
    }
    else {
        return '';
    }
}
