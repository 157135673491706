import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import {
  Button,
  Select,
  FormControl,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import { toastAlert } from "../helper/toastAlert.jsx";
import { useHistory } from "react-router-dom";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { CKEditor } from "ckeditor4-react";
import { useParams } from "react-router-dom";
import { getcmsbyId, updateCms } from "../Action/homePage";
import { gettemplate, updateTemplate } from "../Action/ApiAction.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Error = styled("span")(({ theme }) => ({
  color: red,
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialFormValue = {
  _id: "",
  category: "",
  subcategory: "",
  link: "",
  content: "",
  contentstatus: false,
  type: "",
  // LanName: "terms",
};
export default function HomeCms(props) {
  const history = useHistory();
  const params = useParams();
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [mailContent, setmailContent] = React.useState("");
  const [regbtn, setregbtn] = React.useState(false);
  const [templatename, settemplatename] = useState('terms')

  const { category, subcategory, link, type } = formValue;


  const ChangeEditor = (e) => {
    console.log(e.editor.getData(), "eddd");
    setmailContent(e.editor.getData());
  };

  const mailreciever = async (e) => {
    console.log('mailreciever', e.target.value)
    let value = e.target.value
    var { result } = await gettemplate(value)
    //console.log(result.subject)
    if (result) {
      var template = result.content
    } else {
      var template = `<h1>No Templates</h1>`
    }
    console.log(template, 'templatetemplate-template')
    // setmailContent('')
    setmailContent(template)
    settemplatename(value)
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setregbtn(true);

    try {
      console.log(mailContent, "mailContent");
      if ((mailContent == "" || mailContent == "<h1>No Templates</h1>")) {
        setValidateError({ mailContent: "Content Is Required" });
        setregbtn(false);
        return;
      } else {
        setValidateError({});
      }

      let formdata = { template: mailContent, identifier: templatename }
      console.log(formdata, 'email1')
      let { status, message } = await updateTemplate(formdata)
      if (status == true) {
        setregbtn(false);
        toastAlert("success", message);
      }
      if (status == false) {
        setregbtn(false);
        toastAlert("error", message);
      }
    } catch (err) {
      setregbtn(false);
      console.log("err222: ", err);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  async function getList() {
    var { result } = await gettemplate(templatename);
    console.log(result, "UrlList", result?.subject)
    if (result) {
      var template = result?.content
    } else {
      var template = `<h1>No Templates</h1>`
    }

    setmailContent(template)

  }


  var lanData = [
    { name: "Terms and Condition", value: "terms" },
    { name: "Privacy Policy", value: "privacy" },
    // { name: "FAQ", value: "faq" },
  ];

  console.log(mailContent,'mailContentmailContentwewe')
  return (
    <>
      <ScrollToTopOnMount />
      {console.log(formValue, "0000000")}
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8 col-lg-6 col-xl-5 m-auto">
                    <div className="dashbox_inner">
                      <div className="change_password_panel">
                        <h3>Update CMS</h3>

                        <div className="form-group">
                          <FormControl size="small" fullWidth>
                            <Select
                              onChange={mailreciever}
                              defaultValue="terms"
                              MenuProps={MenuProps}
                              value={templatename}
                              name="cms"
                            >
                              {lanData &&
                                lanData.map((items) => (
                                  <MenuItem
                                    key={items.value}
                                    value={items.value}
                                  >
                                    {items.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>

                        {console.log(mailContent, 'mailContentaaaaaaaaaa')}
                        <div className="form-group">
                          {mailContent && (
                            <CKEditor
                              id="mailContent"
                              name="mailContent"
                              config={{
                                extraAllowedContent: 'div(*)',
                                allowedContent: true,
                                height: 500,
                              }}
                              initData={mailContent && mailContent}
                              onChange={ChangeEditor}
                              key={templatename} 
                            />
                          )}
                          {validateError.mailContent &&
                            validateError.mailContent != "" && (
                              <Error style={{ color: "red" }}>
                                {validateError.mailContent}
                              </Error>
                            )}
                        </div>
                        <div className="text-center">
                          <Button
                            variant="contained"
                            className="round_btn"
                            onClick={handleFormSubmit}
                          >
                            {regbtn && <i class="fas fa-spinner fa-spin"></i>}
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
