import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import * as XLSX from "xlsx/xlsx.mjs";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Bronze(props) {
  useEffect(() => {

   
    AOS.init();
  }, []);
  var itemdataarray = "";
  const [file, setfile] = React.useState(null);

  var obj = {
    FINDBYTOKENORSTATUS: "",
    INSUFFICIENTBALANCE: "",
  };

  var objarray = Object.keys(obj);

  const readdatafromxl = (data) => {
    console.log(data, "datadata");
    let readedData = XLSX.read(data, { type: "binary" });
    console.log(readedData, "readedData");

    const wsname = readedData.SheetNames[0];
    console.log(wsname, "wsname");

    const ws = readedData.Sheets[wsname];
    console.log(ws, "ws");

    /* Convert array to json*/
    const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

    var aoa = [];
    for (var i = 0; i < dataParse.length; ++i) {
      for (var j = 0; j < dataParse[i].length; ++j) {
        if (!aoa[j]) aoa[j] = [];
        aoa[j][i] = dataParse[i][j];
      }
    }

    var newarraydata =
      aoa &&
      aoa.length > 0 &&
      aoa.map((item) => {
        // return (
        itemdataarray = item.slice(1);

        var options = objarray;
        var values = itemdataarray;

        function func(options, values) {
          const objd = {};
          // const objd = [];

          options.forEach((Curr_element, index) => {
            objd[Curr_element] = values[index];
          });
          return objd;
        }

        var dddddddddd = func(options, values);
        console.log("dddddddddd: ", dddddddddd); ////copy this code
      });
  };

  const handlefilexls = async (e) => {
    let MyFile = e.target.files[0];
    setfile(MyFile);
    const data = await MyFile.arrayBuffer();
    readdatafromxl(data);
  };

  // useEffect(() => {
  //   const response = axios
  //     .post(
  //       "https://translation.googleapis.com/language/translate/v2",
  //       {},
  //       {
  //         params: {
  //           q: "muvitha",
  //           target: "hi",
  //           key: "AIzaSyCv69Z2g7KGGCltCNnaFGBOC1EhScaolM8",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       // setConvertedText(response.data.data.translations[0].translatedText);
  //       console.log(
  //         response.data.data.translations[0].translatedText,
  //         "yujfhbhbhbmmmmm"
  //       );
  //     })
  //     .catch((err) => {
  //       console.log("rest api error", err);
  //     });
  // }, []);

  return (
    <div>
      <input type="file" id="myfile" name="myfile" onChange={handlefilexls} />
    </div>
  );
}
