import axios from "../config/axios";
import config from "../config/config";

const baseUrl = config.baseUrl;


export const getcmsList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url:
        `${baseUrl}/cms-list?LanName=` +
        data.LanName +
        `&skip=` +
        data.skip +
        `&limit=` +
        data.limit +
        `&search=` +
        data.search +
        `&status=` +
        data.status +
        `&type=` +
        data.type,
    });
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const getcmsbyId = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/get-cms-id`,
      data: data,
    });
    return {
      result: respData.data.result,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
export const updateCms = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/update-cms`,
      data: data,
    });
    console.log(respData, "updateupdate");
    return {
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};



function returnErr(err) {
  if (err.response && err.response.data && err.response.data.errors) {
    return err.response.data.errors;
  } else {
    return "";
  }
}

export const addsigninmessage = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/add-signin-message`,
      data: data,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      message: respData.data.message,
      success: respData.data.success,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};

export const getsigninmessage = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${baseUrl}/get-signin-message`,
      data: data,
    });
    console.log(respData, "apirespdata");
    return {
      result: respData.data.result,
      totalrecords: respData.data.totalrecords,
      message: respData.data.message,
      status: respData.data.status,
    };
  } catch (err) {
    return {
      errors: returnErr(err),
    };
  }
};
