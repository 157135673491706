import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../config/config";
import Web3 from "web3";
import isEmpty from "../../helper/isEmpty";
import { PoolValidate } from "../validation";
import { toastAlert } from "../../helper/toastAlert.jsx";
import BEP20ABI from "../../ABI/BEP20.json";
import MasterChefABI from "../../ABI/MasterChef.json";
import FactoryABI from "../../ABI/FactoryABI.json";
import { addFarm, checkLPaddress, updateForm } from "../../Action/FarmAction";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Error = styled("span")(({ theme }) => ({
  color: red,
}));
var initialvalue = {
  _id: "",
  user: "",
  name: "",
  risk: "",
  tokenSymbol: "",
  tokenAddresses: "",
  quoteTokenSymbol: "",
  quoteTokenAdresses: "",
  depositFee: "",
  lplogo: "",
  logoURI: "",
  lpAddresses: "",
  pid: "",
};
// eslint-disable-next-line react/prop-types
export default function AddPool(props) {
  console.log(props, "prpppppppppppp");
  var { Edit, Editdata } = props;
  const [startDate, setStartDate] = React.useState(new Date());
  const [farmData, setfarmData] = React.useState(initialvalue);
  const [tokenimage, settokenimage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [validatation, setvalidatation] = React.useState({});
  const onChange = (event) => {
    var newData = {
      [event.target.name]: event.target.value,
    };
    setfarmData({ ...farmData, ...newData });
    setvalidatation({});
  };

  const handleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      settokenimage(true);
      var file = event.target.files[0];
      var url = URL.createObjectURL(file);
      var newData = {
        lplogo: file,
        logoURI: url,
      };
      setfarmData({ ...farmData, ...newData });
      setvalidatation({});
    }
  };

  async function SubmitFarm() {
    await MetaMask();
    var errors = {};
    var web3 = new Web3(window.ethereum);
    let version = await web3.eth.net.getId();
    if (config.netWorkversion !== version) {
      toastAlert("error", "Please Connect BSC Network!");
      return;
    }

    setLoading(true);

    try {
      let validationError = await PoolValidate(farmData);
      if (!isEmpty(validationError)) {
        setvalidatation(validationError);
        setLoading(false);
        return;
      }
      console.log(Edit, "Edit110");
      try {
        var BEP20ContractA = await new web3.eth.Contract(
          BEP20ABI,
          farmData.tokenAddresses
        );
        var isTokenA = await BEP20ContractA.methods.name().call();
        if (isTokenA == "" || !isTokenA) {
          errors.fromaddress = "Please enter valid contract address";
          setLoading(false);
          setvalidatation(errors);
          return;
        }
      } catch (err) {
        errors.fromaddress = "Please enter valid contract address";
        setLoading(false);
        setvalidatation(errors);
        return;
      }

      try {
        setLoading(true);
        var MasterContract = await new web3.eth.Contract(
          MasterChefABI,
          config.masterchef
        );

        try {
          var isowner = await MasterContract.methods.owner().call();
          console.log("isownerssssssssssssss: ", isowner);
        } catch (err) {
          console.log(err, "eeeee");
        }
        window.ethereum.request({ method: "eth_requestAccounts" });

        var address = await web3.eth.getAccounts();
        if (isowner == address[0]) {
          var getBalance = await web3.eth.getBalance(address[0]);
          var balance = getBalance / 10 ** 18;
          if (balance === 0) {
            toastAlert("error", "Insufficient balance for gas fee");
            setLoading(false);
            return;
          }
          if (Edit) {
            var pid = farmData.pid;
            var depositFee = farmData.depositFee * 100;

            try {
              var gasPrice = await web3.eth.getGasPrice();
              var estimateGas = await MasterContract.methods
                .set(pid, depositFee, "True")
                .estimateGas({ from: address[0], gasPrice: gasPrice });
              var estimateGas = estimateGas + 100000;

              if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return toastAlert("error", "Insufficient balance for gas fee");
              }
              await MasterContract.methods.set(pid, depositFee, "True").send({
                from: address[0],
                gasPrice: gasPrice,
                gasLimit: estimateGas,
              });

              const updateFormdata = {
                _id: farmData._id,
                pid: farmData.pid,
                risk: farmData.risk,
                lpSymbol: farmData.lpSymbol,
                isTokenOnly: true,
                lpAddresses: farmData.lpAddresses,
                tokenSymbol: farmData.tokenSymbol,
                tokenAddresses: farmData.tokenAddresses,
                quoteTokenSymbol: "",
                quoteTokenAdresses: "",
                depositFee: farmData.depositFee,
                user: address[0],
                logoURI: farmData.lplogo ? farmData.lplogo : farmData.logoURI,
                file: tokenimage,
              };

              let { success } = await updateForm(updateFormdata);
              if (success == true) {
                window.$("#add_pool").modal("hide");
                toastAlert("success", " Pool Updated  Successfully!");
                setLoading(false);
                setTimeout(function () {
                  window.location.reload();
                }, 1500);
              }
            } catch (err) {
              console.log(err, "eeeee");
              setfarmData(initialvalue);
              window.$("#add_pool").modal("hide");
              toastAlert("error", "Update Pool Rejected!");
              setLoading(false);
            }
          } else {
            console.log("innnnn");
            var lpAddress = farmData.tokenAddresses;
            console.log(lpAddress, "lpAddress");
            var pid = await MasterContract.methods.poolLength().call();

            var depositFee = farmData.depositFee * 100;

            try {
              let data = {
                lpAddresses: lpAddress,
              };
              var { errors } = await checkLPaddress(data);
              console.log("resssssssssst: ", result);
              if (errors == "Already Exists") {
                setLoading(false);
                return toastAlert("error", errors);
              } else {
                var gasPrice = await web3.eth.getGasPrice();
                var estimateGas = await MasterContract.methods
                  .add(lpAddress, depositFee, "True")
                  .estimateGas({ from: isowner, gasPrice: gasPrice });
                var estimateGas = estimateGas + 100000;
                if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                  setLoading(false);
                  return toastAlert(
                    "error",
                    "Insufficient balance for gas fee"
                  );
                }
                var result = await MasterContract.methods
                  .add(lpAddress, depositFee, "True")
                  .send({
                    from: isowner,
                    gasPrice: gasPrice,
                    gasLimit: estimateGas,
                  });

                let fromsymbol = farmData.tokenSymbol.toUpperCase();
                let lpsymbol = `${fromsymbol}`;
                const newForm = {
                  pid: pid,
                  risk: farmData.risk,
                  lpSymbol: lpsymbol,
                  isTokenOnly: true,
                  lpAddresses: lpAddress,
                  tokenSymbol: fromsymbol,
                  tokenAddresses: farmData.tokenAddresses,
                  quoteTokenSymbol: "",
                  quoteTokenAdresses: "",
                  depositFee: farmData.depositFee,
                  logoURI: farmData.lplogo,
                };
                let response = await addFarm(newForm);
                toastAlert("success", "Pool Added Successfully");
                window.$("#add_pool").modal("hide");
                setfarmData(initialvalue);
                setLoading(false);
                setTimeout(function () {
                  window.location.reload();
                }, 1500);
              }
            } catch (err) {
              console.log(err, "23111");
              setfarmData(initialvalue);
              // window.$("#add_pool").modal("hide");
              toastAlert("error", "Rejected");
              setLoading(false);
              return;
            }
          }
        } else {
          toastAlert("error", "please switch to admin address");
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err, "eee250");
        return;
      }
    } catch (err) {
      console.log(err, "eee255");
    }
  }

  const MetaMask = async (e) => {
    try {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        let version = await web3.eth.getChainId();
        if (config.netWorkversion == version) {
          let address = await web3.eth.getAccounts();
          let data = address[0];
          var addressData = {
            user: data,
          };
          setfarmData((prev) => {
            return { ...prev, ...addressData };
          });
        } else {
          toastAlert("error", "Please Connect BSC Network!");
        }
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (Editdata) {
      setfarmData(Editdata);
    }
  }, [Editdata]);
  function handleClose() {
    setfarmData(initialvalue);
    setvalidatation({});
    settokenimage(false);
    window.$("#add_pool").modal("hide");
  }
  return (
    <div
      className="modal fade primary_modal"
      id="add_pool"
      tabindex="-1"
      aria-labelledby="add_pool_modal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="add_poolLabel">
              {Edit == true ? "Update Pool" : "Add Pool"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <form className="primary_form">
              <div className="form-group image_upload">
                <Button component="label" variant="contained">
                  Upload
                  <VisuallyHiddenInput
                    type="file"
                    id="lplogo"
                    onChange={handleChange}
                  />
                </Button>
                {farmData &&
                  farmData.logoURI &&
                  farmData.logoURI != "" &&
                  (tokenimage ? (
                    <img
                      src={farmData.logoURI}
                      alt="Icon"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={`${config.imageUrl + "farms/" + farmData.logoURI}`}
                      alt="Icon"
                      className="img-fluid"
                    />
                  ))}

                {validatation.logoURI && validatation.logoURI != "" && (
                  <Error style={{ color: "red" }}>{validatation.logoURI}</Error>
                )}
              </div>

              <div className="form-group">
                <label>Token Name</label>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    name="tokenSymbol"
                    placeholder="Token Name"
                    aria-describedby="Username"
                    inputProps={{
                      "aria-label": "Username",
                    }}
                    value={farmData.tokenSymbol}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={onChange}
                    disabled={Edit}
                  />
                </FormControl>
                {validatation.tokenSymbol && validatation.tokenSymbol != "" && (
                  <Error style={{ color: "red" }}>
                    {validatation.tokenSymbol}
                  </Error>
                )}
              </div>
              <div className="form-group">
                <label>Token Address</label>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    name="tokenAddresses"
                    placeholder="Enter Token Address"
                    aria-describedby="Username"
                    inputProps={{
                      "aria-label": "Username",
                    }}
                    value={farmData.tokenAddresses}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={onChange}
                    disabled={Edit}
                  />
                </FormControl>
                {validatation.fromaddress && validatation.fromaddress != "" && (
                  <Error style={{ color: "red" }}>
                    {validatation.fromaddress}
                  </Error>
                )}
              </div>

              <div className="form-group">
                <label>Deposit Fee</label>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    name="depositFee"
                    placeholder="Deposit Fee(%)"
                    aria-describedby="Username"
                    inputProps={{
                      "aria-label": "Username",
                    }}
                    value={farmData.depositFee}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={onChange}
                  />
                </FormControl>
                {validatation.depositFee && validatation.depositFee != "" && (
                  <Error style={{ color: "red" }}>
                    {validatation.depositFee}
                  </Error>
                )}
              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  onClick={() => SubmitFarm()}
                  disabled={loading}
                >
                  {" "}
                  {loading && <i class="fas fa-spinner fa-spin"></i>}{" "}
                  {Edit == true ? "Update" : "Add"}{" "}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
