import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import {
  Button,
} from "@mui/material";
import isEmpty from "is-empty";
import { validateImage } from "../components/validation"
import { toastAlert } from "../helper/toastAlert.jsx";
import { getUrlList, siteurl } from "../Action/UserAction"
import { red } from '@mui/material/colors'
import { styled } from "@mui/material/styles";
import config from "../config/config"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  _id: '',
  facebook: '',
  twitter: '',
  linkedin: '',
  instagram: '',
  telegram: '',
  youtube: '',
  logoURI: '',
  lplogo: '',
  fblogo: '',
  fbURI: '',
  linkedinlogo: '',
  linkedinURI: '',
  telegramlogo: '',
  telegramURI: '',
  youtubelogo: '',
  youtubeURI: '',
  reddit: ''
}

const Error = styled('span')(({ theme }) => ({
  color: red,
}))
export default function SiteSettings() {
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [regbtn, setregbtn] = React.useState(false);
  const { facebook, twitter, telegram, youtube, instagram, reddit } = formValue;

  const handleChange = async ({ target: { name, value } }) => {
    let temp = { ...formValue }
    temp[name] = value
    // let isError = await validateUrl(temp)
    // setValidateError({})
    // if (!isEmpty(isError)) {
    //   setValidateError(isError)
    // }
    setFormValue(temp)
  }


  const handleFormSubmit = async (e) => {
    console.log(formValue, 'formValue')
    e.preventDefault();
    setregbtn(true)
    let validationError = await validateImage(formValue)
    if (!isEmpty(validationError)) {
      console.log('validationError: ', validationError);
      setValidateError(validationError)
      setregbtn(false)
      return
    }

    try {
      // var formData = new FormData();
      // formData.append('_id', formValue._id);
      // formData.append('facebook', formValue.facebook);
      // formData.append('twitter', formValue.twitter);
      // formData.append('reddit', formValue.reddit);
      // formData.append('telegram', formValue.telegram);
      // formData.append('youtube', formValue.youtube);
      // formData.append('instagram', formValue.instagram);
      let formData = {
        _id: formValue._id,
        facebook: formValue.facebook,
        twitter: formValue.twitter,
        reddit: formValue.reddit,
        telegram: formValue.telegram,
        youtube: formValue.youtube,
        instagram: formValue.instagram
      }
      let { errors, message, status } = await siteurl(formData);
      if (status == true) {
        setregbtn(false)
        toastAlert('success', message);
        setTimeout(function () {
          window.location.reload()
        }, 1500)
      }
      else if (status == false) {
        setregbtn(false)
        toastAlert('error', message);
      }
      else if (errors) {
        toastAlert('error', errors);
      }
    }
    catch (err) {
      console.log(err, 'errr')
      setregbtn(false)
    }
  }
  useEffect(() => {
    getList()
  }, [])

  async function getList() {

    var response = await getUrlList()
    console.log('resposdsssssssssnse: ', response);
    var list1 = response && response.result ? response.result : {}
    setFormValue(list1)
  }
  return (
    <>
      <ScrollToTopOnMount />
      {console.log(formValue, 'formValue')}
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8 col-lg-6 col-xl-5 m-auto">
                    <div className="dashbox_inner">
                      <div className="change_password_panel">
                        <h3>Site Settings</h3>
                        <div className="form-group">

                          <label class="me-2">FaceBook</label>

                          <input className="form-control" name="facebook"
                            type="text"
                            value={facebook}
                            onChange={handleChange} />
                          {
                            validateError.facebookUrl && <p className="error-message" style={{ color: "red" }}>{validateError.facebookUrl}</p>
                          }

                        </div>
                        <div className="form-group">
                          <label class="me-2">Twitter</label>

                          <input className="form-control" name="twitter"
                            type="text"
                            value={twitter}
                            onChange={handleChange} />
                          {
                            validateError.twitterUrl && <p className="error-message" style={{ color: "red" }}>{validateError.twitterUrl}</p>
                          }
                        </div>
                        <div className="form-group">
                          <label class="me-2">reddit</label>

                          <input className="form-control" name="reddit"
                            type="text"
                            value={reddit}
                            onChange={handleChange} />
                          {
                            validateError.redditUrl && <p className="error-message" style={{ color: "red" }}>{validateError.redditUrl}</p>
                          }
                        </div>
                        <div className="form-group">
                          <label class="me-2">Telegram</label>


                          <input className="form-control" name="telegram"
                            type="text"
                            value={telegram}
                            onChange={handleChange} />
                          {
                            validateError.telegramUrl && <p className="error-message" style={{ color: "red" }}>{validateError.telegramUrl}</p>
                          }
                        </div>
                        <div className="form-group">
                          <label class="me-2">Youtube</label>

                          <input className="form-control" name="youtube"
                            type="text"
                            value={youtube}
                            onChange={handleChange} />
                          {
                            validateError.youtubeUrl && <p className="error-message" style={{ color: "red" }}>{validateError.youtubeUrl}</p>
                          }
                        </div>

                        <div className="form-group image_upload" >
                        </div>

                        <div className="form-group">
                          <label class="me-2">Instagram</label>

                          <input className="form-control" name="instagram"
                            type="text"
                            value={instagram}
                            onChange={handleChange} />
                          {
                            validateError.instagramUrl && <p className="error-message" style={{ color: "red" }}>{validateError.instagramUrl}</p>
                          }
                        </div>

                        <div className="text-center">
                          <Button variant="contained" className="round_btn" onClick={handleFormSubmit}>{regbtn && <i class="fas fa-spinner fa-spin"></i>}Submit</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}