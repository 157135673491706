import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { styled, useTheme } from "@mui/system";

import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";
import Footer from "../components/Footer.js";
import TrafficByLocation from "../components/dashboard/TrafficByLocation.js";
import { ActvityOverviewAction, getdetails } from "../Action/ApiAction.js";
import { getNetworkName, getTxLink } from "../helper/networkdatas.js";
import { tokencheck } from "../helper/jwttoken";
import { dateFormat } from "../helper/dateformat.js";
import TotalVisits from "../components/dashboard/TotalVisits.js";
import LineChart from "../components/dashboard/LineChart.js";
import { capitalizeFirstLetter } from "../helper/capitalize.js";
import { getUserRecentTransaction, getUserList } from "../Action/Swapping.jsx";
import config from "../config/config.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const columns_transaction = [
  {
    name: "User address",
    selector: (row) => row.useraddress,
    width: "40%",
  },

  {
    name: "Type",
    selector: (row) => capitalizeFirstLetter(row.type),
  },
  {
    name: "Date",
    selector: (row) => row.date,
  },
  {
    name: "Hash",
    selector: (recentswaplist) => recentswaplist.txId,
    cell: (recentswaplist) => (
      <a
        href={getTxLink(recentswaplist.chainId) + "tx/" + recentswaplist.txId}
        target="_blank"
      >
        <i className="fa-solid fa-arrow-up-long fa-rotate-45"></i>
      </a>
    ),
  },
];

const columns_users = [
  {
    name: "User address",
    selector: (row) => row.address,
    width: "40%",
  },
  {
    name: "Date",
    selector: (row) => row.date,
  },
];

var init = {
  totalusers: 0,
  totalswapping: 0,
  totalliqutity: 0,
  stakeCounts: 0,
  removecount:0,
  transactioncount:0,
  unstakeCounts: 0,
  tokenscount: 0,
  liqutitychart: [],
  swapchart: [],
};

export default function Dashboard() {
  const theme = useTheme();
  const [activity, setactivity] = useState([]);
  const [MonthCounts, setMonthCounts] = useState(0);
  const [userdetails, setuserdetails] = useState(init);
  const {
    totalusers,
    totalswapping,
    totalliqutity,
    stakeCounts,
    unstakeCounts,
    tokenscount,
    removecount,
    transactioncount
  } = userdetails;
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });

  const [userrecord, setuserRecord] = useState({
    data: [],
    count: 0,
  });

  const [filter, setFilter] = useState({
    skip: 1,
    limit: 5,
    search: "",
  });
  useEffect(() => {
    details();
  }, []);

  const details = async () => {
    let res = await getdetails();
    console.log("rssssssssssssssssssssssses: ", res);
    setuserdetails(res);
  };

  useEffect(() => {
    getSwapList(filter);
    getListTwo(filter);
    ActvityOverview()
  }, []);

  const ActvityOverview = async () => {
    const { result } = await ActvityOverviewAction();
    console.log('resulaaaaaaaaaaaaaaaaaaasasasast: ', result);
    let activities =
      result && result[0] && result[0].activity ? result[0].activity : [];
    let monthcounts =
      result &&
        result[0] &&
        result[0].Monthcount[0] &&
        result[0].Monthcount[0].count
        ? result[0].Monthcount[0].count
        : 0;
    setactivity(activities);
    setMonthCounts(monthcounts);
  };
  async function getSwapList(reqQuery) {
    console.log("reqQuery: ", reqQuery);
    var { result, totalrecords } = await getUserRecentTransaction(reqQuery);
    console.log("resulsssssssssssssssst: ", result);
    let resultArr = [];
    result &&
      result.length > 0 &&
      result.map((item, key) => {
        resultArr.push({
          _id: item?._id,
          type: item?.type,
          txId: item?.txid,
          // "chainId": config.netWorkversion,
          date: moment(item?.createdAt).format("MMMM DD,YYYY hh:mm"),
          useraddress: item?.useraddress,
        });
      });
    setRecord({
      data: resultArr,
      count: totalrecords,
    });
  }

  async function getListTwo(reqQuery) {
    var { result, totalrecords } = await getUserList(reqQuery);
    let resultArr = [];
    result &&
      result.length > 0 &&
      result.map((item, key) => {
        resultArr.push({
          _id: item?._id,
          address: item?.address,
          date: moment(item?.createdAt).format("MMMM DD,YYYY hh:mm"),
        });
      });
    setuserRecord({
      data: resultArr,
      count: totalrecords,
    });
  }

  // For Datatable Starts
  createTheme(
    "dark",
    {
      text: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(0,0,0,.12)",
      },
      background: {
        default: "#201f21",
      },
      context: {
        background: "#201f21",
        text: "#DA1818",
      },
      divider: {
        default: "rgb(32 31 33)",
      },
      button: {
        default: "#FFFFFF",
        focus: "rgba(255, 255, 255, .54)",
        hover: "rgba(255, 255, 255, .12)",
        disabled: "rgba(255, 255, 255, .18)",
      },
      selected: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      highlightOnHover: {
        default: "rgba(0, 0, 0, .7)",
        text: "#FFFFFF",
      },
      striped: {
        default: "rgba(0, 0, 0, .87)",
        text: "#FFFFFF",
      },
    },
    "dark"
  );
  // For Datatable Ends

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <h2 className="page_title">Dashboard</h2>
                <div className="row py-2">
                  <div className="col-lg-12">
                    <div className="widget_panel">
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#FFD329"
                            number={false}
                            percent={75}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Users</h6>
                          <h5>
                            {Number.isInteger(totalusers)
                              ? parseInt(totalusers)
                              : parseFloat(totalusers).toFixed(6)}
                          </h5>
                        </div>
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#1AA053"
                            number={false}
                            percent={62}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Swapping</h6>
                          <h5>
                            {Number.isInteger(totalswapping)
                              ? parseInt(totalswapping)
                              : parseFloat(totalswapping).toFixed(6)}
                          </h5>
                        </div>
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#08B1BA"
                            number={false}
                            percent={71}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Add Liquidity</h6>
                          <h5>
                            {Number.isInteger(totalliqutity)
                              ? parseInt(totalliqutity)
                              : parseFloat(totalliqutity).toFixed(6)}
                          </h5>
                        </div>                
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#08B1BA"
                            number={false}
                            percent={71}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Remove Liquidity</h6>
                          <h5>
                            {Number.isInteger(removecount)
                              ? parseInt(removecount)
                              : parseFloat(removecount).toFixed(6)}
                          </h5>
                        </div>            
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="widget_panel">
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#FFD329"
                            number={false}
                            percent={80}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Tokens</h6>
                          <h5>
                            {Number.isInteger(tokenscount)
                              ? parseInt(tokenscount)
                              : parseFloat(tokenscount).toFixed(6)}
                          </h5>
                        </div>
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#08B1BA"
                            number={false}
                            percent={62}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total stake</h6>
                          <h5>
                            {Number.isInteger(stakeCounts)
                              ? parseInt(stakeCounts)
                              : parseFloat(stakeCounts).toFixed(6)}
                          </h5>
                        </div>
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#FFD329"
                            number={false}
                            percent={80}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Unstake</h6>
                          <h5>
                            {Number.isInteger(unstakeCounts)
                              ? parseInt(unstakeCounts)
                              : parseFloat(unstakeCounts).toFixed(6)}
                          </h5>
                        </div>
                      </div>
                      <div className="widget_panel_single">
                        <div className="widget_panel_single_inner">
                          <CircularProgressBar
                            colorCircle="#8A92A6"
                            colorSlice="#08B1BA"
                            number={false}
                            percent={71}
                            strokeBottom={2}
                            stroke={5}
                            size={68}
                            animationSmooth="1s ease-out"
                            round
                          >
                            <img
                              src={require("../assets/images/cross_arrow_icon.png")}
                              className="img-fluid"
                            />
                          </CircularProgressBar>
                        </div>
                        <div>
                          <h6 className="ylw_txt">Total Transactions</h6>
                          <h5>
                            {Number.isInteger(transactioncount)
                              ? parseInt(transactioncount)
                              : parseFloat(transactioncount).toFixed(6)}
                          </h5>
                        </div>            
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-2">
                  {/* <div className="col-lg-6">
                    <div className="dashbox mb-3">
                      <div className="dashtitle_flex">
                        <h2 className="dash_inner_title">Users List</h2>
                      </div>
                      <TotalVisits />
                    </div>
                  </div> */}

                


                </div>
                <div className="row py-2">
                  <div className="col-lg-6">
                    <div className="dashbox mb-3">
                      <div className="dashtitle_flex">
                        <h2 className="dash_inner_title">
                          Users List
                        </h2>
                      </div>
                      <TrafficByLocation />
                    </div>
                  </div>
                  <div className="col-lg-6">

                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex">
                        <h2 className="dash_inner_title">Activity overview</h2>
                      </div>
                      <div className="profit_activity">
                        <p className="mb-0">
                          {MonthCounts} transactions this month
                        </p>
                        <i className="fa-solid fa-arrow-up green_text"></i>
                      </div>
                      <ul className="profit_activity_list">
                        {activity &&
                          activity.length > 0 &&
                          activity.map((item) => {
                            return (
                              <>
                                {item &&
                                  (
                                    <li>
                                      <p>
                                        {(item.useraddress).substring(0, 6) + '.....' + (item.useraddress).substring(37, 42)}, (
                                        {capitalizeFirstLetter(item.type)} <a href={getTxLink(config.netWorkversion) + "tx/" + item.txid} target="_blank"><i className="fa-solid fa-arrow-up-long fa-rotate-45"></i></a>)
                                      </p>
                                      <span>
                                        {moment(item.createdAt).format(
                                          "D MMM h:mm A"
                                        )}
                                      </span>
                                    </li>
                                  )}

                              </>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="col-lg-12">
                    <div className="dashbox mb-3">
                      {userdetails &&
                        userdetails.liqutitychart &&
                        userdetails.swapchart && (
                          <LineChart
                            height="315px"
                            color={[theme.palette.primary.main, "#FF007F"]}
                            liqutitydata={userdetails.liqutitychart}
                            swapdata={userdetails.swapchart}
                          />
                        )}
                    </div>
                  </div>
                <div className="row py-2">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex justify-content-start">
                        <h2 className="dash_inner_title ylw_txt">
                          Recent Transactions
                        </h2>

                        <Link className="grey_txt ms-4" to="/transactions">
                          See all
                        </Link>
                      </div>
                      <DataTable
                        columns={columns_transaction}
                        data={record.data}
                        responsive
                        theme="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex justify-content-start">
                        <h2 className="dash_inner_title ylw_txt">
                          Recent Users
                        </h2>

                        <Link className="grey_txt ms-4" to="/users-list">
                          See all
                        </Link>
                      </div>
                      <DataTable
                        columns={columns_users}
                        data={userrecord.data}
                        responsive
                        theme="dark"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row py-2">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex justify-content-start">
                        <h2 className="dash_inner_title ylw_txt">
                          Recently Swap Transaction
                        </h2>

                        <Link
                          className="grey_txt ms-4"
                          to="/swap-transaction"
                        >
                          See all
                        </Link>

                      </div>
                      <DataTable
                        columns={columns_swap_trans}
                        data={recentswaplist}
                        responsive
                        theme="dark"
                      />
                    </div>
                  </div>
                </div> */}
                {/* <div className="row py-2">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex justify-content-start">
                        <h2 className="dash_inner_title ylw_txt">
                          Recently Bridge Transaction
                        </h2>


                        <Link
                          className="grey_txt ms-4"
                          to="/bridge-management"
                        >
                          See all
                        </Link>

                      </div>
                      <DataTable
                        columns={columns_bridge_trans}
                        data={recentbridgelist}
                        responsive
                        theme="dark"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
