let key = {};
if (process.env.NODE_ENV === "production") {
  key = {
    txLink: {
      1: "https://etherscan.io/",
      56: "https://bscscan.com/",
      137: "https://polygonscan.com/",
      43114: "https://snowtrace.io/",
      250: "https://ftmscan.com/",
      42161: "https://arbiscan.io/",
      10: "https://optimistic.etherscan.io/",
      97: "https://testnet.bscscan.com/",
    },
    rpc: {
      97: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
      1: "https://mainnet.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      56: "https://bsc-dataseed.binance.org/",
      137: "https://polygon-rpc.com/",

      43114: "https://api.avax.network/ext/bc/C/rpc",
      250: "https://rpcapi.fantom.network",
      42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      10: "https://mainnet.optimism.io",
      128: "https://http-mainnet-node.huobichain.com",
      8453: "https://mainnet.base.org",
    },
    defaultLogo: "https://dexapi.metadiacdemo.com/tokens/default.png",
    imageUrl: "https://dexapi.metadiacdemo.com/",
    baseUrl: "https://dexapi.metadiacdemo.com/adminapi",
    p2pUrl: "http://localhost:2053",
    p2pimageUrl: "https://p2papi.inwdex.com/",
    farmImg: "https://dexapi.metadiacdemo.com/farms/",
    gateway: "https://indxbuytokenapi.alwin.io/v2",
    loaderColor: "#0a0a3a",
    txUrl: "https://testnet.bscscan.com/tx/",
    factory: "0xE7110D6aE62a9E93fc00d54e17B9b317ef4A3034",
    masterchef: "0xA87aEB9a2E1fc0A5B66Bf0179Ae2cC11908724f5",
    netWorkUrl: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
    netWorkversion: 97,
    secretkey: "DOPE*26@7#$%@",
    wallet: {
      chainName: "Bsc test network",
      chainId: 97,
      name: "Bsc",
      symbol: "BNB", // 2-6 characters long
      decimals: 18,
      rpcUrls: ["https://data-seed-prebsc-2-s2.bnbchain.org:8545"],
    },
    cryptoSecretKey: "1234567812345678",
  };
} else {
  key = {
    txLink: {
      1: "https://etherscan.io/",
      56: "https://bscscan.com/",
      137: "https://polygonscan.com/",
      43114: "https://snowtrace.io/",
      250: "https://ftmscan.com/",
      42161: "https://arbiscan.io/",
      10: "https://optimistic.etherscan.io/",
      97: "https://testnet.bscscan.com/",
    },
    rpc: {
      97: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
      1: "https://mainnet.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
      56: "https://bsc-dataseed.binance.org/",
      137: "https://polygon-rpc.com/",

      43114: "https://api.avax.network/ext/bc/C/rpc",
      250: "https://rpcapi.fantom.network",
      42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
      10: "https://mainnet.optimism.io",
      128: "https://http-mainnet-node.huobichain.com",
      8453: "https://mainnet.base.org",
    },
    defaultLogo: "https://dexapi.metadiacdemo.com/tokens/default.png",
    imageUrl: "http://localhost:3533/",
    baseUrl: "http://localhost:3533/adminapi",
    // baseUrl: "https://dexapi.metadiacdemo.com/adminapi",
    p2pUrl: "http://localhost:2053",
    p2pimageUrl: "https://p2papi.inwdex.com/",
    farmImg: "http://localhost:3533/farms/",
    gateway: "https://indxbuytokenapi.alwin.io/v2",
    loaderColor: "#0a0a3a",
    txUrl: "https://testnet.bscscan.com/tx/",
    factory: "0xE7110D6aE62a9E93fc00d54e17B9b317ef4A3034",
    masterchef: "0xA87aEB9a2E1fc0A5B66Bf0179Ae2cC11908724f5",
    netWorkUrl: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
    netWorkversion: 97,
    secretkey: "DOPE*26@7#$%@",
    wallet: {
      chainName: "Bsc test network",
      chainId: 97,
      name: "Bsc",
      symbol: "BNB", // 2-6 characters long
      decimals: 18,
      rpcUrls: ["https://data-seed-prebsc-2-s2.bnbchain.org:8545"],
    },
    cryptoSecretKey: "1234567812345678",
  };
}

export default key;
