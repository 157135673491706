import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import $ from "jquery";
import io from 'socket.io-client';
import config from "../config/config"


let socket;
const url = config.socketUrl
socket = io(url)

export default function Navbar(props) {
  let { setOnlineUsers, setArrivalMessage, setsocket } = props;
  // let { socket, setOnlineUsers,setArrivalMessage } = props;
  const history = useHistory()
  const [data, setdata] = useState({});
  const [resctriction, setResctriction] = useState([]);
 
  useEffect(() => {
    loadScript();
  }, []);



  // window.addEventListener('unload', () => {
  //   console.log('disconnectMe');
  //   socket.emit("removeUser", socket.id);
  // });

  const Logout = () => {
    localStorage.clear()
    // socket.emit("removeUser", socket.id);
    history.push('/login')
  }


  function loadScript() {
    // Navbar Sticky
    var t = $(".app_header");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      30 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      30 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      30 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    // Sidebar Add Class
    $(document).ready(function () {
      $('.topnavbar_right i').click(function () {
        $('.app_sidebar').addClass('show');
        $('body').addClass('menu_overlay');
      });
    });
  }



  // Get Page header from URL
  const location = useLocation();
  const path = location.pathname;
  const pageName = path.split('/').map(segment => segment.replace(/-/g, ' ')).join(' ');

  return (
    <>
      <div className="app_header">
        <div className="container-fluid">
          <header className="py-3 navbar_main">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              <div className="topnavbar_right">
                <div className="dropdown text-end usericon_dropdown">
                  <a
                    href="#"
                    className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={require("../assets/images/user_img.jpg")}
                      alt="User"
                      width="52"
                      height="52"
                      className="rounded-circle"
                    />
                    <span>Admin</span>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark">
                  
                      <li>
                        <Link className="dropdown-item" to="/site-settings">
                          Settings
                        </Link>
                      </li> 
                    <hr className="m-0" />
                    <li>
                      <Link className="dropdown-item" to="#" onClick={Logout}>
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
          </header>

        </div>
      </div>
    </>
  );
}
