import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import logo from "../assets/images/logo.jpg";
import {
    Button,
} from "@mui/material";
import isEmpty from "is-empty";
import { ResetPasswordvalidation } from "./validation.js";
import { toastAlert } from "../helper/toastAlert.jsx";
import { ResetPasswordAction, getAdminDetailsAction } from "../Action/ApiAction.js";
import { getAuthToken } from "../helper/localstorage.js";
import { useHistory, useParams } from "react-router-dom";
// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}
const initialFormValue = {
    'password': '',
    'confirmPassword': ''
}
export default function ResetPassword() {
    const history = useHistory()
    const { userId } = useParams();
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const { password, confirmPassword } = formValue;
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        if (!isEmpty(validateError)) {
            setValidateError({})
        }
    }
    useEffect(() => {
        getAdminDetails();
    }, []);

    const getAdminDetails = async () => {
        let data = {
            randomno: userId,
        };
        const { result } = await getAdminDetailsAction(data);
        if (result) {
            if (result.activeDatas == "Active") {
                toastAlert("error", "You already used this Link");
                history.push("/login");
            } else {
            }
        }
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        var token = getAuthToken()
        let reqData = {
            password: password,
            confirmPassword: confirmPassword,
            userId: userId,
        }
        let validationError = ResetPasswordvalidation(reqData)
        if (!isEmpty(validationError)) {
            setValidateError(validationError)
            return
        }
        try {
            let { status, errors } = await ResetPasswordAction(reqData);
            if (status == true) {
                setFormValue(initialFormValue)
                history.push('/dashboard')
                toastAlert('success', 'Password changed successfully');
            } else {
                if (errors) {
                    setValidateError(errors);
                    toastAlert('error', errors, 'link');
                }
            }
        }
        catch (err) {
            console.log('err222: ', err);
        }
    }
    return (
        <>
            <ScrollToTopOnMount />
            <div className="apppage_wrapper">
                <div className="app_page">
                    <Navbar />
                    <div className="page_wrapper">
                        <div className="page_content">
                            <div className="container-fluid">
                                <div className="row py-3">
                                    <h2 className="page_title">Reset Password</h2>
                                    <div className="col-md-8 col-lg-6 col-xl-5 m-auto">
                                        <div className="dashbox_inner">
                                            <div className="change_password_panel">
                                                <img src={logo} alt="Logo" className="img-fluid" />
                                                <h3>Password Help</h3>
                                                <div className="form-group">
                                                    <label>New password</label>
                                                    <input className="form-control" type="password" name="password"
                                                        value={password}
                                                        onChange={handleChange} />
                                                    {
                                                        validateError.password && <p className="error-message" style={{ color: "red" }}>{validateError.password}</p>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm New password</label>
                                                    <input className="form-control" type="password" name="confirmPassword"
                                                        value={confirmPassword}
                                                        onChange={handleChange} />
                                                    {
                                                        validateError.confirmPassword && <p className="error-message" style={{ color: "red" }}>{validateError.confirmPassword}</p>
                                                    }
                                                </div>
                                                <div className="text-center">
                                                    <Button variant="contained" onClick={handleFormSubmit}>Submit</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}