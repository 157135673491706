import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import logo from "../assets/images/logo.jpg";
import {
  Button,
} from "@mui/material";
import isEmpty from "is-empty";
import { changePasswordvalidation } from "./validation.js";
import { toastAlert } from "../helper/toastAlert.jsx";
import { changePassword } from "../Action/ApiAction.js";
import { getAuthToken } from "../helper/localstorage.js";
import { useHistory } from "react-router-dom";
import config from "../config/config"
import clsx from "classnames";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  'oldPassword': '',
  'password': '',
  'confirmPassword': '',
  'showPassword':false,
  'showNewpassword':false,
  'showconfirmpassword':false

}
export default function ChangePassword() {
  const history = useHistory()
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { oldPassword, password, confirmPassword,showPassword,showNewpassword,showconfirmpassword } = formValue;
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    if (!isEmpty(validateError)) {
      setValidateError({})
    }
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    var token = getAuthToken()
    let reqData = {
      oldPassword: oldPassword,
      password: password,
      confirmPassword: confirmPassword,
      token: token
    }
    let validationError = changePasswordvalidation(reqData)
    if (!isEmpty(validationError)) {
      setValidateError(validationError)
      return
    }

    try {
    console.log('reqDaasdasdsadddddddddddta: ', reqData);

      let { success, errors, message } = await changePassword(reqData);
      if (!isEmpty(errors)) {
       
        setValidateError(errors)
        return
      }
      if (success == true) {
        setFormValue(initialFormValue)
        history.push('/dashboard')
        toastAlert('success', 'Password changed successfully');
      }
      else if (success == false) {
        toastAlert('error', message);
      
      }
      else if (errors) {
        toastAlert('error', errors);
      }
    }
    catch (err) {
    }
  }

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="container-fluid">
                <h2 className="page_title">Change Password</h2>
                <div className="row py-3">
                  <div className="col-md-8 col-lg-6 col-xl-5 m-auto">
                    <div className="dashbox_inner">
                      <div className="change_password_panel">
                      <img src={logo} alt="Logo" className="img-fluid" />
                        <h3>Password Help</h3>
                    
                        <div className="form-group">
                          <label>Current password</label>
                          <div class="password-container">
                          <input className="form-control" type={showPassword ? "text" : "password"} name="oldPassword"
                            value={oldPassword}
                            onChange={handleChange} />
                            <a
              onClick={(e) => {
                e.preventDefault();
                setFormValue((el) => {
                  return { ...el, ...{ showPassword: !el.showPassword } };
                });
              }}
              class="toggle-password"
            >
                             <i
                className={clsx(
                  "fa",
                  { "fa-eye": showPassword },
                  { "fa-eye-slash": !showPassword }
                )}
                aria-hidden="true"
              ></i>
              </a>
              </div>
                          {
                            validateError.oldPassword && <p className="error-message" style={{ color: "red" }}>{validateError.oldPassword}</p>
                          }
                        </div>
                        <div className="form-group">
                          <label>New password</label>
                          <div class="password-container">
                          <input className="form-control" type={showNewpassword ? "text" : "password"} name="password"
                            value={password}
                            onChange={handleChange} />
                              <a
              onClick={(e) => {
                e.preventDefault();
                setFormValue((el) => {
                  return { ...el, ...{ showNewpassword: !el.showNewpassword } };
                });
              }}
              class="toggle-password"
            >
                             <i
                className={clsx(
                  "fa",
                  { "fa-eye": showNewpassword },
                  { "fa-eye-slash": !showNewpassword }
                )}
                aria-hidden="true"
              ></i>

              </a>
              </div>
                          {
                            validateError.password && <p className="error-message" style={{ color: "red" }}>{validateError.password}</p>
                          }
                        </div>
                        <div className="form-group">
                          <label>Confirm New password</label>
                          <div class="password-container">
                          <input className="form-control" type={showconfirmpassword ? "text" : "password"} name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange} />
                              <a
              onClick={(e) => {
                e.preventDefault();
                setFormValue((el) => {
                  return { ...el, ...{ showconfirmpassword: !el.showconfirmpassword } };
                });
              }}
              class="toggle-password"
            >
                             <i
                className={clsx(
                  "fa",
                  { "fa-eye": showconfirmpassword },
                  { "fa-eye-slash": !showconfirmpassword }
                )}
                aria-hidden="true"
              ></i>
              </a>
              </div>
                          {
                            validateError.confirmPassword && <p className="error-message" style={{ color: "red" }}>{validateError.confirmPassword}</p>
                          }
                        </div>
                      
                          <div className="text-center">
                            <Button variant="contained" className="round_btn" onClick={handleFormSubmit}>Submit</Button>
                          </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}